.App {
  text-align: auto;
  overflow-x: hidden;
  transition: 1s;
}

.mobile-calendar-style {
  top: 10px;
}

.react-datepicker__triangle {
  left: 22px !important;
}

@media (max-width: 780px) {
  .mobile-calendar-style {
    left: -120px;
    top: 8px;
  }

  .react-datepicker__triangle {
    left: 142px !important;
  }
}
